export const BASE_URL = process.env.REACT_APP_BASE_URL
export const API_URL = `${BASE_URL}/api/`
export const API_V2_URL = `${BASE_URL}/api-v2/`
export const LOGIN_URL = `${BASE_URL}/login`
export const LANDING_URL = process.env.REACT_APP_LANDING_URL
export const SUPERSET_URL = process.env.REACT_APP_SUPERSET_URL
export const SUPERSET_ID_LENDER = process.env.REACT_APP_LENDER_SUPERSET_ID
export const SUPERSET_ID_COMMERCE = process.env.REACT_APP_COMMERCE_SUPERSET_ID
export const API_KEY = process.env.REACT_APP_API_KEY
export const API_PASSWORD = process.env.REACT_APP_API_PASSWORD
export const GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID
export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_ID
export const COUNTRY = process.env.REACT_APP_COUNTRY
export const DEBT_COLLECTION_URL = process.env.REACT_APP_DEBT_COLLECTION_URL
export const CURRENCY = ''

const SUPERSET_USERNAME = process.env.REACT_APP_SUPERSET_USERNAME
const SUPERSET_PASSWORD = process.env.REACT_APP_SUPERSET_PASSWORD

export const platonDataForPayment = {
  PAYMENT: 'C2A',
  CURRENCY: 'UAH',
  DESCRIPTION: 'Loan repayment',
  REQ_TOKEN: '1'
}

export const supersetLoginPayload = {
  username: SUPERSET_USERNAME,
  password: SUPERSET_PASSWORD,
  provider: 'db',
  refresh: false
}

export const supersetTokenPayload = {
  user: {
    username: SUPERSET_USERNAME,
    first_name: 'saas',
    last_name: 'saas'
  },
  resources: [{
    type: 'dashboard',
    id: SUPERSET_ID_LENDER
  }],
  rls: []
}

export const colors = {
  darkBlue: '#000015',
  menuBar: {
    // first: '#F5F778',
    // second: '#B0C8F5',
    // third: '#AEDC7C',
    // fourth: '#FB8A66',
    // fifth: '#F67CD7',
    // sixth: '#6FDECE',
    // seventh: '#5F98F2',
    // eighth: '#C3B1E1',
    // ninth: '#FAA0A0',
    hover: '#6e73ff',
    active: '#6e73ff'
  },
  createButton: {
    main: '#85CDC0',
    hover: '#4fb19f'
  }
}
