import { IApplication, IGuarantorModel, IPaymentInfo, IQuestionary } from 'redux/reducers/client/allState/types/stateTypes'
import { TState } from 'redux/store'
import { IToastMessage } from 'types/common/types'

export const paymentGraphicSelector = (state: TState) => state.client.all.applicationForm.paymentGraphic

export const currentQuestionarySelector = (state: TState): IQuestionary | null =>
  state.client.all.applicationForm.currentQuestionary

export const currentClientPolicySelector = (state: TState) =>
  state.client.all.applicationForm.currentQuestionary?.credit_policy

export const applicationStatusSelector = (state: TState) => state.client.all.applicationForm.status

export const appFormFetchingStatusSelector = (state: TState) =>
  state.client.all.applicationForm.serviceStatus

export const appFormToastMessageSelector = (state: TState): IToastMessage | null =>
  state.client.all.applicationForm.toastMessage

export const appFormTemplateTypeSelector = (state: TState) =>
  state.client.all.applicationForm.templateType

export const applicationFormIdSelector = (state: TState): number | null =>
  state.client.all.applicationForm.applicationId

export const applicationFormCollateralsSelector = (state: TState) =>
  state.client.all.applicationForm.collaterals

export const appFormSmsStatusSelector = (state: TState): string | null =>
  state.client.all.applicationForm.SMSStatus

export const appFormContractSelector = (state: TState) =>
  state.client.all.applicationForm.contract

export const appFormInvoiceSelector = (state: TState) => state.client.all.applicationForm.invoice

export const appFormCreditPolicyData = (state: TState) =>
  state.client.all.applicationForm.shopRequestData?.credit_policy

export const appFormCollateralsData = (state: TState) =>
  state.client.all.applicationForm.shopRequestData?.credit_policy.collaterals

export const appFormShopRequestDataSelector = (state: TState) => state.client.all.applicationForm.shopRequestData

export const appFormShopRequestDataApplicationSelector = (state: TState) =>
  state.client.all.applicationForm.shopRequestData?.application

// active loans
export const clientActiveLoansListSelector = (state: TState): IApplication[] =>
  state.client.all.activeLoans.list

export const currentActiveLoanSelector = (state: TState): IApplication | null =>
  state.client.all.activeLoans.current

export const activeLoansInitFetchingStatusSelector = (state: TState): boolean =>
  state.client.all.activeLoans.initFetchingStatus

export const activeLoansModalFetchingStatusSelector = (state: TState): boolean =>
  state.client.all.activeLoans.modalFetchingStatus

// guarantor
export const guarantorApplicationsSelector = (state: TState): IApplication[] =>
  state.client.all.guarantor.applications.list

export const guarantorModelssSelector = (state: TState): IGuarantorModel[] =>
  state.client.all.guarantor.models.list

export const guarantorFetchingStatusSelector = (state: TState): boolean =>
  state.client.all.guarantor.fetchingStatus

export const paymentInfoSelector = (state: TState): IPaymentInfo | null => state.client.all.paymentInfo

// client toast messages
export const clientToastMessageSelector = (state: TState): IToastMessage | null =>
  state.client.all.toastMessage

// client is loading
export const clientIsLoadingSelector = (state: TState): boolean =>
  state.client.all.isLoading
