import { saveAs } from 'file-saver'
import moment from 'moment'
import { ThunkAction } from 'redux-thunk'

import { clientApi } from 'api/clientApi'
import { servicesApi } from 'api/servicesApi'
import { setAppFormUserFlow } from 'redux/reducers/auth/authReducer'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { authActionTypes } from 'redux/reducers/auth/types/actionTypes'
import {
  setAppFormFetchingStatus,
  setApplicationId,
  setClientActiveLoansList,
  SetApplicationStatus,
  setApplicationsToBePaid,
  setContract,
  setCurrentQuestionary,
  setGuarantorApplications,
  setGuarantorFetchingStatus,
  setGuarantorModels,
  setClientToastMessage,
  setPaymentGraphic,
  setPaymentInfo,
  setShopRequestData,
  SetSMSStatus,
  setActiveLoansInitFetchungStatus,
  setActiveLoansModalFetchingStatus,
  setClientCurrentActiveLoan,
  setUuidToken,
  setAppFormCollaterals,
  setAppFormCollateralsStatus,
  setInvoice,
  deleteAppFormCollaterals, setShopRequestDataApplication
} from 'redux/reducers/client/allState/clientReducer'
import { actionTypes } from 'redux/reducers/client/allState/types/actionTypes'
import { IApplication, IGuarantorModel, TApplicationDocument } from 'redux/reducers/client/allState/types/stateTypes'
import { IClientCollateral } from 'redux/reducers/lender/allState/types/state'
import { TState } from 'redux/store'
import { getDeviceType } from 'utils/getDeviceType'

type clientThunk = ThunkAction<void, TState, unknown, actionTypes | authActionTypes>

export const applicationRequest =
  (uuid: string): clientThunk =>
    async (dispatch, getState) => {
      dispatch(setUuidToken(uuid))
      dispatch(setAppFormFetchingStatus('load'))

      const res = await clientApi.applicationForm.getShopRequest(uuid)

      if (res.status === 200) {
        const { credit_policy, uuid, product_price, loan_term, application } = res.data
        dispatch(setShopRequestData(res.data))

        const { currentUser, isAuth } = getState().auth

        // якщо це нова заявка то створити
        if (!application && isAuth) {
          const data = {
            scoring_model: credit_policy.scoring_model,
            shop_request: uuid,
            user: currentUser?.id,
            status: 1,
            loan_amount: product_price,
            loan_date_from: moment(),
            loan_date_to: moment().add(loan_term, 'd')
          }

          const appRes = await clientApi.applicationForm.postApplication(data)
          if (appRes.status === 201) {
            await postApplicationByIdChecks(appRes.data.id, { device: getDeviceType() })
            const userFlowRes = await clientApi.applications.postUserFlowData({
              uuid,
              id: appRes.data.id,
              step: 0
            })

            if (userFlowRes.status === 201) {
              dispatch(setAppFormUserFlow(userFlowRes.data))
              localStorage.removeItem('client_uuid_token')
              dispatch(setAppFormFetchingStatus('success'))
              window.location.href = `${window.location.origin}/client/application`
              return true
            }
          }
        } else {
          localStorage.removeItem('client_uuid_token')
          dispatch(setAppFormFetchingStatus('success'))
          return true
        }
      }

      dispatch(setAppFormFetchingStatus('error'))
      return false
    }

export const applicationRiskScoreRequest =
  (appId: number): clientThunk =>
    async (dispatch) => {
      dispatch(setAppFormFetchingStatus('load'))

      const res = await clientApi.applicationForm.getClientApplicationsById(appId)

      if (res.status === 200) {
        dispatch(setShopRequestDataApplication(res.data))
      }

      dispatch(setAppFormFetchingStatus('error'))
      return false
    }

export const updateShopRequestApplicationThunk =
  (id: number, data: Partial<IApplication>): clientThunk =>
    async (dispatch, getState) => {
      try {
        const res = await clientApi.applications.patchApplication(id, data)

        if (res.status === 200) {
          const uuid = getState().client.all.applicationForm.shopRequestData?.uuid

          if (!uuid) return false
          const res2 = await clientApi.applicationForm.getShopRequest(uuid)

          if (res2.status === 200) {
            dispatch(setShopRequestData(res2.data))
          }

          return true
        }
      } catch (err: any) {
        return false
      }
    }

export const setPaymentGraphicThunk =
  (
    credit_policy_id: number,
    amount: number,
    loan_term: number,
    category_id: number,
    promo_code: string | null,
    setApplicationPayments?: any | undefined
  ): clientThunk =>
    async dispatch => {
      const res = await clientApi.applications.getPaymentGraphic(credit_policy_id, amount, loan_term, category_id, promo_code)

      if (res.status === 200) {
        if (setApplicationPayments) {
          dispatch(setApplicationPayments(res.data))
        }
        dispatch(setPaymentGraphic(res.data))
      }
    }

export const sendQuestionaryFormThunk =
  (policyId: number, questionaryId: number, applicationId: number, appData: any): clientThunk =>
    async (dispatch, getState) => {
      const { status } = getState().client.all.applicationForm
      try {
        const res = await clientApi.applicationForm.sendQuestionaryFormFirstRequest(
          policyId,
          questionaryId,
          appData
        )

        if (res.status === 201) {
          try {
            const res2 = await clientApi.applications.patchApplicationById(
              applicationId,
              'questionary',
              res.data.id
            )

            if (res2.status === 200) {
              dispatch(setAppFormFetchingStatus('success'))
              dispatch(setClientToastMessage({ type: 'success', message: 'Application created!' }))
              dispatch(SetApplicationStatus(status + 1))
            }
          } catch (err: any) {
            dispatch(setAppFormFetchingStatus('success'))
            dispatch(setClientToastMessage({ type: 'error', message: err.message }))
          }
        }
      } catch (err: any) {
        dispatch(setAppFormFetchingStatus('success'))
        dispatch(setClientToastMessage({ type: 'error', message: err.message }))
      }
    }

export const postApplicationThunk =
  (data: any): clientThunk =>
    async dispatch => {
      try {
        const res = await clientApi.applicationForm.postApplication(data)

        if (res.status === 201) {
          dispatch(setApplicationId(res.data.id))
        }
      } catch (error: any) {
        dispatch(
          setClientToastMessage({
            type: 'error',
            message: `${error.message} id postApplicationThunk`
          })
        )
      }
    }

export const postApplicationByIdChecks =
  async (appId: number, data: { q_inserts?: number; q_replaces?: number; contract_time?: number; device?: string }) => {
    await clientApi.applicationForm.postApplicationByIdChecks(appId, data)
      .then(() => {
        localStorage.removeItem('countClearInput')
      })
  }

export const getContractThunk = (): clientThunk => async dispatch => {
  const res = await servicesApi.getClientContracts()

  if (res.status === 200) {
    const contract_id = res.data[res.data.length - 1].pk
    const contract = await clientApi.applicationForm.getContract(contract_id)

    dispatch(setContract(contract.data))
  }
}
export const getClientContractByAppIdThunk =
  (appId: number, typeDocument: TApplicationDocument): clientThunk =>
    async dispatch => {
      const res = await clientApi.applicationForm.getClientDocumentByAppId(appId, typeDocument)

      if (res.status === 200) {
        switch (typeDocument) {
          case 'Contract': {
            dispatch(setContract(res.data[res.data.length - 1]))
            break
          }
          case 'Invoice': {
            dispatch(setInvoice(res.data[res.data.length - 1]))
            break
          }
          case 'Additional contract': {
            dispatch(setContract(res.data[res.data.length - 1]))
            break
          }
        }
        return true
      } else {
        return false
      }
    }

export const downloadContractByDocIdThunk =
  (docId: number, setFetchingStatus: (status: boolean) => void | undefined): clientThunk =>
    async dispatch => {
      try {
        const res = await clientApi.applicationForm.getClientDocumentByDocIdFile(docId)

        if (res.status === 200) {
          saveAs(new Blob([res.data]), 'contract.pdf')
        }
      } catch (err: any) {
        dispatch(setClientToastMessage({ type: 'error', message: err.message }))
      } finally {
        setFetchingStatus(false)
      }
    }
export const getActiveLoansThunk = (): clientThunk => async dispatch => {
  const res = await clientApi.applications.getApplications()

  if (res.status === 200) {
    dispatch(setClientActiveLoansList(res.data))
    dispatch(setActiveLoansInitFetchungStatus(false))
    dispatch(setApplicationsToBePaid(res.data))
  }
}

export const getPaymentInfoThunk =
  (applicationId: number): clientThunk =>
    async dispatch => {
      const res = await servicesApi.getClientRequestPayentInfo(applicationId)
      if (res.status === 200) {
        dispatch(setPaymentInfo(res.data))
      }
    }
export const getSMSCodeThunk =
  (id: number, contractTime: number): clientThunk =>
    async () => {
      await clientApi.applicationForm.getSMSCode(id)
      await postApplicationByIdChecks(id, { contract_time: contractTime })
    }

export const sendSMSCodeThunk =
  (applicationId: number, code: string): clientThunk =>
    async dispatch => {
      const res = await clientApi.applicationForm.checkSMSCode(applicationId, code)

      if (res.status === 200) {
        dispatch(SetSMSStatus('Successful'))
        // clientApi.applications.postUserFlowData(null)
      } else {
        dispatch(SetSMSStatus(res.data.message))
      }
    }

export const completeFormThunk =
  (applicationId: number, status: number): clientThunk =>
    async dispatch => {
      try {
        const res = await clientApi.applicationForm.completeForm(applicationId, status)

        if (res.status === 200) {
          dispatch(setCurrentQuestionary(null))
        }
      } catch (err: any) {
        dispatch(setClientToastMessage({ type: 'error', message: err.message }))
      }
    }

// guarantor
export const createGuarantorModelThunk =
  (data: IGuarantorModel): clientThunk =>
    async (dispatch, getState) => {
      const { appFormFlowData } = getState().auth.userFlow
      if (!appFormFlowData) return
      const { flow_data } = appFormFlowData
      try {
        const res = await clientApi.guarantor.postModel(data)

        if (res.status === 201) {
          const { user, email, first_name, last_name, phone_number, uuid } = res.data

          if (user) {
            dispatch(updateAppFormUserFlowThunk({ step: flow_data.step + 1 }))
            dispatch(
              setClientToastMessage({
                type: 'success',
                message: 'Guarantor model was successful created!'
              })
            )
            dispatch(setAppFormFetchingStatus('success'))
            dispatch(setActiveLoansModalFetchingStatus(false))
            dispatch(setClientCurrentActiveLoan(null))
          } else {
            try {
              const registerRes = await clientApi.guarantor.postRegistery(
                { email, first_name, last_name, phone_number, password: 'tibro321' },
                uuid
              )

              if (registerRes.status === 201) {
                dispatch(
                  setClientToastMessage({
                    type: 'success',
                    message: 'Guarantor was successful register!'
                  })
                )
                dispatch(updateAppFormUserFlowThunk({ step: flow_data.step + 1 }))
              }
            } catch (err: any) {
              const errorsData = err.response.data

              if (errorsData) {
                const fields = Object.keys(errorsData)
                const errorsString = fields
                  .map(field => `${field}: ${errorsData[field].join(',')}`)
                  .join(' ')
                dispatch(setClientToastMessage({ type: 'error', message: errorsString }))
              } else {
                dispatch(setClientToastMessage({ type: 'error', message: err.message }))
              }
            } finally {
              dispatch(setAppFormFetchingStatus('success'))
              dispatch(setActiveLoansModalFetchingStatus(false))
              dispatch(setClientCurrentActiveLoan(null))
            }
          }
        }
      } catch (err: any) {
        const errorsData = err.response.data
        if (errorsData) {
          const fields = Object.keys(errorsData)
          const errorsString = fields
            .map(field => `${field}: ${errorsData[field].join(',')}`)
            .join(' ')

          dispatch(setClientToastMessage({ type: 'error', message: errorsString }))
        } else {
          dispatch(setClientToastMessage({ type: 'error', message: err.message }))
        }
        dispatch(setAppFormFetchingStatus('success'))
        dispatch(setActiveLoansModalFetchingStatus(false))
        dispatch(setClientCurrentActiveLoan(null))
      }
    }
export const getGuarantorModelsThunk = (): clientThunk => async dispatch => {
  try {
    const res = await clientApi.guarantor.getModels()

    if (res.status === 200) {
      dispatch(setGuarantorModels(res.data))
      dispatch(setGuarantorFetchingStatus(false))
    }
  } catch (error) {
    console.log(error)
  }
}
export const downloadGuarantorContractByModelUuidThunk =
  (applicationId: number, typeDocument: TApplicationDocument, setFetchingStatus: (status: boolean) => void | undefined): clientThunk =>
    async dispatch => {
      try {
        const currentDocument = await clientApi.applicationForm.getClientDocumentByAppId(applicationId, typeDocument)

        const document = currentDocument?.data[currentDocument?.data.length - 1]
        if (document) {
          const res = await clientApi.applicationForm.getClientDocumentByDocIdFile(document.id!)

          if (res.status === 200) {
            const blob = new Blob([res.data])
            saveAs(blob, `${document.document_type}.pdf`)
          }
        }
      } catch (err: any) {
        dispatch(setClientToastMessage({ type: 'error', message: err.message }))
      } finally {
        setFetchingStatus(false)
      }
    }
export const getGuarantorApplicationsThunk = (): clientThunk => async dispatch => {
  try {
    const res = await clientApi.guarantor.getApplications()

    if (res.status === 200) {
      dispatch(setGuarantorApplications(res.data))
      dispatch(setGuarantorFetchingStatus(false))
    }
  } catch (error) {
    console.log(error)
  }
}

export const getClientCollateralsThunk =
  (applicationId: number): clientThunk =>
    async dispatch => {
      try {
        const res = await clientApi.applicationForm.getClientCollateral(applicationId)

        if (res.status === 200) {
          dispatch(setAppFormCollaterals(res.data))
        }
      } catch (e: any) {
        dispatch(setClientToastMessage({ type: 'error', message: e.message }))
      }
    }
export const createClientCollateralThunk =
  (applicationId: number, data: IClientCollateral, reset?: () => void): clientThunk =>
    async (dispatch, getState) => {
      dispatch(setAppFormCollateralsStatus('load'))
      const { list } = getState().client.all.applicationForm.collaterals

      try {
        const res = await clientApi.applicationForm.postClientCollateral(applicationId, data)

        if (res.status === 201) {
          dispatch(setAppFormCollaterals([...list, res.data]))
          dispatch(
            setClientToastMessage({ type: 'success', message: 'Client collateral was created' })
          )
          dispatch(setAppFormCollateralsStatus('success'))

          if (reset) reset()
        }
      } catch (e: any) {
        dispatch(setClientToastMessage({ type: 'error', message: e.message }))
        dispatch(setAppFormCollateralsStatus('init'))
      }
    }

export const deleteClientCollateralThunk = (applicationId: number, collateralId: number, reset?: () => void): clientThunk =>
  async (dispatch) => {
    dispatch(setAppFormCollateralsStatus('load'))
    try {
      const res = await clientApi.applicationForm.deleteClientCollateral(applicationId, collateralId)

      if (res.status === 200) {
        dispatch(deleteAppFormCollaterals(collateralId))
        dispatch(
          setClientToastMessage({ type: 'success', message: 'Client collateral was deleted' })
        )
        dispatch(setAppFormCollateralsStatus('success'))

        if (reset) reset()
      }
    } catch (e: any) {
      dispatch(setClientToastMessage({ type: 'error', message: e.message }))
      dispatch(setAppFormCollateralsStatus('init'))
    }
  }

export const deleteUserFlow = (flowId: number, showError: boolean = true): clientThunk => async (dispatch, getState) => {
  try {
    await clientApi.applicationForm.deleteUserFlow(flowId)
  } catch {
    if (showError) {
      dispatch(setClientToastMessage({ type: 'error', message: 'Cannot remove userFlow' }))
    }
  }
}
